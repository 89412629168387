import React from "react";

const Folio = () => {
  return (
    <svg
      version="1.0"
      id="projectsIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20.662px"
      height="21.662px"
      viewBox="0 0 20.662 21.662"
      enableBackground="new 0 0 20.662 21.662"
    >
      <path
        className="iconFill"
        d="M18.53,18.826H2.132C0.957,18.826,0,17.869,0,16.692V8.31c0-1.175,0.957-2.131,2.132-2.131h2.425V4.235
  c0-0.771,0.629-1.399,1.401-1.399h8.745c0.771,0,1.398,0.627,1.398,1.399v1.944h2.429c1.176,0,2.132,0.957,2.132,2.132v8.381
  C20.662,17.869,19.706,18.826,18.53,18.826z M2.132,7.497c-0.449,0-0.813,0.365-0.813,0.813v8.382c0,0.45,0.365,0.815,0.813,0.815
  H18.53c0.448,0,0.813-0.365,0.813-0.815V8.311c0-0.449-0.365-0.814-0.813-0.814H2.132z M5.875,5.898h8.908V4.235
  c0-0.044-0.036-0.081-0.08-0.081H5.958c-0.046,0-0.083,0.036-0.083,0.081V5.898z"
      />
      <path
        className="iconCenterFill"
        opacity="0"
        d="M2.132,7.497c-0.449,0-0.813,0.365-0.813,0.813v8.382c0,0.45,0.365,0.815,0.813,0.815H18.53
  c0.448,0,0.813-0.365,0.813-0.815V8.311c0-0.449-0.365-0.814-0.813-0.814H2.132z M5.875,5.898h8.908V4.235
  c0-0.044-0.036-0.081-0.08-0.081H5.958c-0.046,0-0.083,0.036-0.083,0.081V5.898z"
      />
    </svg>
  );
};

export default Folio;
