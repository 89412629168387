import React from "react";

const WebSiteLink = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20px"
    // height="auto"
    viewBox="0 0 18.927 17.201"
    enableBackground="new 0 0 18.927 17.201"
  >
    <g id="WEBSITE">
      <path
        className="iconFill"
        d="M16.794,0H2.133C0.957,0,0,0.956,0,2.132v8.383c0,1.175,0.957,2.131,2.133,2.131h14.661
    		c1.176,0,2.133-0.956,2.133-2.131V2.132C18.927,0.956,17.97,0,16.794,0z M17.609,10.515c0,0.448-0.365,0.812-0.814,0.812H2.133
    		c-0.449,0-0.815-0.364-0.815-0.812V2.132c0-0.449,0.366-0.814,0.815-0.814h14.661c0.449,0,0.814,0.365,0.814,0.814V10.515z
    		 M13.833,13.591H5.089c-0.771,0-1.399,0.628-1.399,1.399v0.81c0,0.772,0.628,1.4,1.399,1.4h8.745c0.773,0,1.402-0.628,1.402-1.4
    		v-0.81C15.236,14.219,14.607,13.591,13.833,13.591z M13.917,15.8c0,0.045-0.038,0.082-0.084,0.082H5.089
    		c-0.044,0-0.081-0.037-0.081-0.082v-0.81c0-0.045,0.036-0.081,0.081-0.081h8.745c0.046,0,0.084,0.037,0.084,0.081V15.8z"
      />
    </g>
  </svg>
);
export default WebSiteLink;
