import React from "react";

const About = () => {
  return (
    <svg
      version="1.0"
      id="AboutIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="14.866px"
      height="18.049px"
      viewBox="24.057 4.174 14.866 18.049"
      enableBackground="new 24.057 4.174 14.866 18.049"
    >
      <path
        id="AboutIcon_1_"
        className="iconFill"
        d="M33.517,7.433h-6.154v6.061h6.154V7.433z M32.28,12.258h-3.682V8.668h3.682V12.258z
  M27.389,16.447h8.359v-1.322h-8.359V16.447z M27.389,19.021h6.109v-1.322h-6.109V19.021z M36.777,4.174H26.202
  c-1.183,0-2.146,0.99-2.146,2.208v13.634c0,1.218,0.963,2.208,2.146,2.208h10.575c1.183,0,2.146-0.99,2.146-2.208V6.382
  C38.923,5.165,37.96,4.174,36.777,4.174z M37.577,20.016c0,0.476-0.358,0.862-0.8,0.862H26.202c-0.441,0-0.8-0.387-0.8-0.862V6.382
  c0-0.475,0.358-0.862,0.8-0.862h10.575c0.441,0,0.8,0.387,0.8,0.862V20.016z"
      />
      <path
        id="AboutIcon_2_"
        opacity="0"
        className="iconCenterFill"
        d="M33.517,7.433h-6.154v6.061h6.154V7.433z M32.28,12.258h-3.682V8.668h3.682
  V12.258z M27.389,16.447h8.359v-1.322h-8.359V16.447z M27.389,19.021h6.109v-1.322h-6.109V19.021z M37.577,20.016
  c0,0.476-0.358,0.862-0.8,0.862H26.202c-0.441,0-0.8-0.387-0.8-0.862V6.382c0-0.475,0.358-0.862,0.8-0.862h10.575
  c0.441,0,0.8,0.387,0.8,0.862V20.016z"
      />
    </svg>
  );
};

export default About;
