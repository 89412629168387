import React, { useContext } from "react";

import { ThemeContext } from "../../context/themeContext";

const ThemeSwitch = () => {
  const { toggleTheme } = useContext(ThemeContext);
  return (
    <button className="themeSwitchButton" onClick={e => toggleTheme()}>
      Toggle theme
    </button>
  );
};

export default ThemeSwitch;
