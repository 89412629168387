import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [dataTheme, setDataTheme] = useState("dark");
  const toggleTheme = () => {
    const theme = dataTheme === "light" ? "dark" : "light";
    document.documentElement.classList.add("colorTransition");
    setDataTheme(theme);
    localStorage.setItem("dataTheme", theme);
    document.documentElement.setAttribute("dataTheme", theme);
    window.setTimeout(() => {
      document.documentElement.classList.remove("colorTransition");
    }, 500);
  };
  const getTheme = () => {
    localStorage.getItem("dataTheme") &&
      setDataTheme(localStorage.getItem("dataTheme"));
  };
  const setTheme = () => {
    if (localStorage.getItem("dataTheme") !== "light") {
      document.documentElement.setAttribute("dataTheme", "dark");
      localStorage.setItem("dataTheme", "dark");
    }
  };
  return (
    <ThemeContext.Provider
      value={{ dataTheme, toggleTheme, getTheme, setTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
