import React from "react";
import MoreArrow from "./Icons/MoreArrow";
import { Link } from "react-router-dom";

const ProjectList = ({ projectInfo, isDesktop, developer }) => {
  return projectInfo.map(
    ({ id, projectName, img, description, imgWidth, imgHeight }) => {
      return (
        <div className="portfolioItem designingWork" key={id}>
          <ul id="hovereffect" className="overlay">
            <li className="portfolioListItem">
              <Link to={`projects/${id - 1}`}>
                <img
                  src={require(`../images/thumbnails/${img}`).default}
                  alt={img}
                  width={imgWidth}
                  height={imgHeight}
                />
                <div className="portfolioTextContainer">
                  <h4>
                    <span>Project: </span>
                    {projectName}
                  </h4>
                  <h5>
                    {isDesktop
                      ? description.substring(0, 40) + "...click for more"
                      : description}
                  </h5>
                  <h6> – view project – </h6>
                  <MoreArrow />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      );
    }
  );
};

export default ProjectList;
