import React from "react";
import { HashLink } from "react-router-hash-link";

const Logo = () => (
  <div className="Logo">
    <HashLink
      id="home"
      smooth="true"
      to="/#intro"
      className="Logo__logoContainer"
      aria-label="Site Logo"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 40 40"
        className="Logo__logoFront"
      >
        <style type="text/css"></style>
        <circle className="st0" cx="20" cy="20" r="20" />
        <path
          className="st1"
          d="M29.7 13.3c0 0 4.6 0.5 4.8 5.4 0.1 3-2.4 5.4-5.4 5.4 -1.5 0-2.4-0.4-3.3-1.1l-5.8-4.4 0 0v0l-5.4-4c-1.1-0.8-2.5-1.3-4-1.3C7 13.3 4 16.3 4 20c0 3.5 2.7 6.4 6.1 6.7 0 0-4.6-0.5-4.8-5.4 -0.1-3 2.4-5.4 5.4-5.4 1.5 0 2.4 0.4 3.2 1.1l4.7 3.5 6.4 4.8c1.1 0.8 2.5 1.3 4 1.3 3.7 0 6.7-3 6.7-6.7C35.8 16.5 33.1 13.6 29.7 13.3z"
        />
        <path
          className="st2"
          d="M16.2 26c-0.2 0.2 0 0.4 0.1 0.4h1.9c0.3 0 0.6-0.1 0.9-0.5 0 0 1.1-1.5 2.4-3.2l-1.8-1.4L16.2 26zM28.6 13.3c-2.7 0.2-4 1.6-5 2.9l-2.5 3.2 1.8 1.4c1.4-1.9 2.7-3.5 2.7-3.6 1-1.4 2.5-1.7 3.3-1.7 1.4 0 3.7 0.6 4.4 3.5 0 0 0 0.1 0.1 0.1 0 0 0.1 0 0.1-0.1C34.1 15.5 31.2 13.1 28.6 13.3zM23.6 14c0.2-0.2 0-0.4-0.1-0.4h-1.9c-0.3 0-0.6 0.1-0.9 0.5 0 0-6.5 8.5-6.5 8.6 -1 1.4-2.5 1.7-3.3 1.7 -1.4 0-3.7-0.6-4.4-3.5 0 0 0-0.1-0.1-0.1 0 0-0.1 0-0.1 0.1 -0.6 3.6 2.3 5.9 4.8 5.7 2.7-0.2 4-1.6 5-2.9L23.6 14z"
        />
        <path
          className="st3"
          d="M19.8 21.3l-0.2 0.3 1.5 1.8c0.2-0.2 0.4-0.5 0.6-0.8L19.8 21.3zM20.1 18.7l-1.4 1.8 0.6 0.5 1.1-2.1L20.1 18.7z"
        />
      </svg>
    </HashLink>
  </div>
);
export default Logo;
