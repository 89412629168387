import React from "react";

const BackEndIcon = () => {
  return (
    <figure className="iconBorder">
      <svg
        version="1.1"
        id="SCREEN"
        className="iconMain"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="21.662px"
        height="17.2px"
        viewBox="0 0 21.662 17.2"
        enableBackground="new 0 0 21.662 17.2"
      >
        <path
          opacity="0"
          className="iconCenterFill"
          d="M6.157,15.88h9.01v-1.056h-9.01V15.88z M19.529,1.318H2.131c-0.448,0-0.813,0.365-0.813,0.814
   v8.381c0,0.449,0.365,0.814,0.813,0.814h17.398c0.449,0,0.814-0.365,0.814-0.814V2.132C20.344,1.683,19.979,1.318,19.529,1.318z"
        />
        <path
          className="iconFill"
          d="M19.529,0H2.131C0.956,0,0,0.956,0,2.132v8.381c0,1.176,0.956,2.133,2.131,2.133h17.398
   c1.176,0,2.133-0.957,2.133-2.133V2.132C21.662,0.956,20.705,0,19.529,0z M20.344,10.513c0,0.449-0.365,0.814-0.814,0.814H2.131
   c-0.448,0-0.813-0.365-0.813-0.814V2.132c0-0.449,0.365-0.814,0.813-0.814h17.398c0.449,0,0.814,0.365,0.814,0.814V10.513z
   M15.013,13.591H6.265c-0.771,0-1.399,0.629-1.399,1.401V15.8c0,0.771,0.628,1.399,1.399,1.399h8.748
   c0.771,0,1.399-0.628,1.399-1.399v-0.808C16.412,14.22,15.784,13.591,15.013,13.591z M15.094,15.8c0,0.045-0.036,0.081-0.081,0.081
   H6.265c-0.045,0-0.081-0.036-0.081-0.081v-0.808c0-0.046,0.036-0.083,0.081-0.083h8.748c0.045,0,0.081,0.037,0.081,0.083V15.8z"
        />
      </svg>
    </figure>
  );
};

export default BackEndIcon;
