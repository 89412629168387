import React, { createContext, useState } from "react";

export const LoadedContext = createContext();

export const LoadedProvider = ({ children }) => {
  const [isLoaded, setisLoaded] = useState(false);
  const showLoader = () => {
    document.addEventListener("DOMContentLoaded", function () {
      setisLoaded(false);
      setTimeout(function () {
        document.body.classList.add("loaded");
      }, 3000);
      // console.log("DOM fully loaded");
    });
    setisLoaded(true);
  };
  return (
    <LoadedContext.Provider value={{ isLoaded, showLoader }}>
      {children}
    </LoadedContext.Provider>
  );
};
