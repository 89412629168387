import React from "react";

const DownArrow = () => {
  return (
    <svg
      version="1.1"
      id="DOWN-ARROW-CIRC"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      className="iconMain"
      width="17.504px"
      height="17.504px"
      viewBox="0 0 17.504 17.504"
      enableBackground="new 0 0 17.504 17.504"
    >
      <path
        id="arrowDown"
        className="iconFill"
        d="M12.409,6.989l-3.657,3.656L5.095,6.989c-0.257-0.258-0.675-0.258-0.932,0c-0.257,0.257-0.257,0.674,0,0.932
  l3.788,3.788c0.221,0.221,0.51,0.331,0.8,0.331s0.58-0.11,0.8-0.331l3.788-3.788c0.258-0.257,0.258-0.674,0-0.932
  C13.083,6.731,12.667,6.731,12.409,6.989z"
      />
    </svg>
  );
};

export default DownArrow;
