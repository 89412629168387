import { useContext } from "react";
import { Link } from "react-router-dom";

//components
import SubHeader from "./SubHeader";

//Icons
import GitHub from "./Icons/GitHub";
import WebSiteLink from "./Icons/WebSiteLink";
import Home from "./Icons/Home";

//contexts
import { RoleContext } from "../context/roleContext";

const Projects = props => {
  const { developer } = useContext(RoleContext);

  const { match, projectInfo } = props;
  const {
    project,
    projectDetails,
    folder,
    id,
    url,
    git,
    technologies,
    challenges
  } = projectInfo[match.params.projectid];
  const projectOutline = projectDetails.map(
    ({ image, desc, moreinfo, projText }, index) => (
      <section className="projectImagesContainer" key={index}>
        {desc && (
          <p id={`descriptionText${index}`} className="descriptionText">
            {desc}
          </p>
        )}
        {moreinfo && (
          <p id={`additionalText${index}`} className="additionalText">
            {moreinfo}
          </p>
        )}
        <div className="projectImagesContainer__wrapper">
          {projText && <p className="projText">{projText}</p>}
          <div className="projectImagesContainer__container">
            <img
              className={`folioImage ${developer} ? ${developer} : "" `}
              id={`client${id}_image${index}`}
              src={require(`../images/projects/${folder}/${image}`).default}
              alt="image1"
            />
          </div>
          <div className="textureDiv"></div>
        </div>
      </section>
    )
  );
  return (
    <>
      <SubHeader {...props} projectInfo={projectInfo} />
      <section className="page_container">
        <div className="wrapper">
          <div id="portfolio" className="portfolio">
            <div className="folio">
              <div className="folioInfoContainer">
                <h5>
                  <span>Project details:</span> {project}
                </h5>
                {challenges && (
                  <h5>
                    <span>Challenges:</span> {challenges}
                  </h5>
                )}
                {technologies && (
                  <h5>
                    <span>Technologies used:</span> {technologies}
                  </h5>
                )}
                {!developer && (
                  <div className="linksContainer" id="portfolio">
                    <a
                      id="github"
                      className="externalPageLinks"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={git}
                    >
                      View on Github
                      <GitHub />
                    </a>
                    <a
                      id="liveProject"
                      className="externalPageLinks"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={url}
                    >
                      View live project{""}
                      <WebSiteLink />
                    </a>
                  </div>
                )}
              </div>
              <Link id="linkHome" className="externalPageLinks" to="/">
                Back to homepage{""}
                <Home />
              </Link>
            </div>
            {projectOutline}
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
