import React from "react";

const Home = () => (
  <svg
    version="1.1"
    id="nextArrow"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="25px"
    // height="auto"
    viewBox="0 0 17.504 17.504"
    enableBackground="new 0 0 17.504 17.504"
  >
    <symbol id="Arrow" viewBox="-4.782 -2.623 9.563 5.244">
      <path
        className="iconFill"
        stroke="#3A3D3F"
        strokeWidth="1.3181"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M-4.123,1.963
  		l3.788-3.788c0.185-0.185,0.484-0.185,0.669,0l3.788,3.788"
      />
    </symbol>
    <symbol id="buttonContainerCircle" viewBox="-8.752 -8.752 17.504 17.504">
      <path
        fill="none"
        stroke="#3A3D3F"
        strokeWidth="1.3181"
        strokeMiterlimit="10"
        d="M8.093,0c0-4.47-3.624-8.093-8.093-8.093
  		S-8.093-4.469-8.093,0S-4.47,8.093,0,8.093S8.093,4.47,8.093,0z"
      />
    </symbol>
    <path
      opacity="0"
      fill="#EC008C"
      d="M8.752,1.318c-4.099,0-7.434,3.334-7.434,7.434c0,4.099,3.334,7.434,7.434,7.434
  	s7.434-3.335,7.434-7.434C16.186,4.653,12.851,1.318,8.752,1.318z"
    />
    <path
      className="homeFill"
      d="M8.752,0C3.926,0,0,3.926,0,8.752c0,4.826,3.926,8.752,8.752,8.752s8.752-3.926,8.752-8.752
  	C17.504,3.926,13.578,0,8.752,0z M8.752,16.186c-4.099,0-7.434-3.335-7.434-7.434c0-4.099,3.334-7.434,7.434-7.434
  	s7.434,3.334,7.434,7.434C16.186,12.851,12.851,16.186,8.752,16.186z M12.409,6.989l-3.657,3.656L5.095,6.989
  	c-0.257-0.258-0.675-0.258-0.932,0c-0.257,0.257-0.257,0.674,0,0.932l3.788,3.788c0.221,0.221,0.51,0.331,0.8,0.331
  	s0.58-0.11,0.8-0.331l3.788-3.788c0.258-0.257,0.258-0.674,0-0.932C13.083,6.731,12.667,6.731,12.409,6.989z"
    />
  </svg>
);
export default Home;
