import React from "react";

const DesignIcon = () => {
  return (
    <figure className="iconBorder">
      <svg
        className="iconMain"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="12.647px"
        height="26.026px"
        viewBox="0 0 12.647 26.026"
        enableBackground="new 0 0 12.647 26.026"
      >
        <path
          className="iconFill"
          d="M6.324,20.624c-0.616,0-1.115,0.499-1.115,1.113c0,0.616,0.499,1.115,1.115,1.115
  c0.615,0,1.114-0.499,1.114-1.115C7.438,21.123,6.939,20.624,6.324,20.624z M8.784,7.151c0.008-0.008,0.02-0.011,0.027-0.02
  c0.653-0.603,0.982-1.467,0.982-2.566c0-2.503-1.758-3.4-3.345-3.402C5.783,1.103,5.504,0.929,5.504,0H4.187
  c0,1.515,0.721,2.348,2.2,2.479C7.34,2.482,8.476,2.847,8.476,4.566c0,0.712-0.187,1.249-0.554,1.593
  c-0.583,0.548-1.475,0.5-1.487,0.496l0,0.01c-0.038-0.001-0.073-0.011-0.11-0.011C2.836,6.653,0,9.49,0,12.977v6.728
  c0,3.484,2.836,6.323,6.324,6.323s6.323-2.839,6.323-6.323v-6.728C12.647,10.362,11.052,8.113,8.784,7.151z M11.329,19.704
  c0,2.759-2.244,5.004-5.004,5.004s-5.006-2.245-5.006-5.004v-6.728c0-2.76,2.246-5.005,5.006-5.005s5.004,2.245,5.004,5.005V19.704z
  "
        />
        <path
          opacity="0"
          className="iconCenterFill"
          d="M6.324,20.624c-0.616,0-1.115,0.499-1.115,1.113c0,0.616,0.499,1.115,1.115,1.115
  c0.615,0,1.114-0.499,1.114-1.115C7.438,21.123,6.939,20.624,6.324,20.624z M11.329,19.704c0,2.759-2.244,5.004-5.004,5.004
  s-5.006-2.245-5.006-5.004v-6.728c0-2.76,2.246-5.005,5.006-5.005s5.004,2.245,5.004,5.005V19.704z"
        />
      </svg>
    </figure>
  );
};

export default DesignIcon;
