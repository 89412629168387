import React from "react";

const Circles = () => {
  return (
    <svg
      version="1.1"
      id="circle"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      className="circles"
      viewBox="0 0 23.083 23.083"
      enableBackground="new 0 0 23.083 23.083"
    >
      <path
        className="circleSmall"
        d="M11.542,8.229c-1.827,0-3.313,1.486-3.313,3.312c0,1.827,1.486,3.313,3.313,3.313s3.312-1.486,3.312-3.313
  			C14.854,9.715,13.369,8.229,11.542,8.229z"
      />
      <path
        className="circleMedium"
        d="M11.542,0C5.167,0,0,5.167,0,11.542c0,6.375,5.167,11.542,11.542,11.542c6.374,0,11.542-5.167,11.542-11.542
  			C23.083,5.167,17.916,0,11.542,0z M11.542,18.855c-4.039,0-7.313-3.274-7.313-7.313c0-4.039,3.274-7.312,7.313-7.312
  			c4.039,0,7.312,3.274,7.312,7.312C18.854,15.581,15.581,18.855,11.542,18.855z"
      />
      <path
        className="circleBig"
        d="M11.542,8.229c1.827,0,3.312,1.486,3.312,3.312c0,1.827-1.486,3.313-3.312,3.313s-3.313-1.486-3.313-3.313
  		C8.229,9.715,9.715,8.229,11.542,8.229 M11.542,4.229c-4.039,0-7.313,3.274-7.313,7.312c0,4.039,3.274,7.313,7.313,7.313
  		c4.039,0,7.312-3.274,7.312-7.313C18.854,7.503,15.581,4.229,11.542,4.229L11.542,4.229z"
      />
    </svg>
  );
};

export default Circles;
