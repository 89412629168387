import React, { createContext, useContext } from "react";
import { ThemeContext } from "./themeContext";
import useToggle from "../hooks/useToggle";

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const { toggleTheme } = useContext(ThemeContext);

  const [developer, setDeveloper] = useToggle(false);
  const handleSwitch = (e) => {
    e.preventDefault();
    setDeveloper();
    toggleTheme();
  };
  return (
    <RoleContext.Provider value={{ developer, handleSwitch }}>
      {children}
    </RoleContext.Provider>
  );
};
