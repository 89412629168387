import React, { useEffect, useState } from "react";
import TriangleArrowBtn from "../Icons/TriangleArrowBtn";
import * as _ from "underscore";

const Topbutton = () => {
  const [showButton, setShowButton] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  // show/hide/fade button
  const scrollToTop = (e) => {
    let windowY = window.scrollY;
    let offset = 300,
      offsetOpacity = 1000;
    setShowButton(windowY > offset ? true : false);
    setFadeOut(windowY > offsetOpacity ? true : false);
  };
  // throttle scroll function
  const handleInputThrottled = _.debounce(scrollToTop, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleInputThrottled, false);
    return () => {
      window.removeEventListener("scroll", handleInputThrottled);
    };
  }, [handleInputThrottled]);

  const handleClick = (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      onClick={handleClick}
      className={`upButton ${showButton ? "upButtonVisible" : ""} ${
        fadeOut ? "upButtonFadeOut" : ""
      }`}
      aria-label="Back to top button"
    >
      <TriangleArrowBtn />
    </button>
  );
};
export default Topbutton;
