import React from "react";

const MoreArrow = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16.434px"
      height="22.68px"
      viewBox="62.648 0 16.434 22.68"
      enableBackground="new 62.648 0 16.434 22.68"
      className="nav_arrow"
    >
      <path
        className="iconFill"
        d="M70.865,0c-4.52,0-8.217,3.862-8.217,8.628c0,1.726,0.657,3.698,1.89,5.424l6.328,8.628l6.328-8.546
  		c1.232-1.644,1.889-3.698,1.889-5.506C79.082,3.863,75.385,0,70.865,0z M75.96,13.148l-5.095,6.903l-5.095-6.903
  		c-1.068-1.479-1.562-3.04-1.562-4.438c0-3.944,2.958-7.066,6.656-7.066c3.697,0,6.656,3.122,6.656,6.984
  		C77.521,10.108,76.946,11.751,75.96,13.148z M70.865,4.273c-2.383,0-4.273,2.055-4.273,4.52c0,2.466,1.89,4.521,4.273,4.521
  		c2.383,0,4.273-2.055,4.273-4.521C75.139,6.327,73.248,4.273,70.865,4.273z M70.865,11.669c-1.479,0-2.712-1.315-2.712-2.877
  		c0-1.561,1.233-2.876,2.712-2.876c1.479,0,2.712,1.315,2.712,2.876C73.577,10.354,72.344,11.669,70.865,11.669z"
      />
    </svg>
  );
};

export default MoreArrow;
