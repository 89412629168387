import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

//import styles
import "./styles/styles.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

//contexts
import { LoadedProvider } from "./context/loadedContext";
import { ThemeProvider } from "./context/themeContext";
import { DeviceProvider } from "./context/deviceContext";
import { RoleProvider } from "./context/roleContext";

render(
  <React.StrictMode>
    <DeviceProvider>
      <LoadedProvider>
        <ThemeProvider>
          <RoleProvider>
            <Router>
              <App />
            </Router>
          </RoleProvider>
        </ThemeProvider>
      </LoadedProvider>
    </DeviceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
