import React from "react";
import { HashLink } from "react-router-hash-link";

export const NoMatch = ({ location }) => (
  <div className="NoMatch">
    <div className="NoMatchContainer">
      <h2>
        Sorry, it looks like you went astray, there was no match for
        <code>{location.pathname}</code>, please try again
      </h2>
      <button className="mainBtn">
        <HashLink smooth to="/#intro">
          Return to homepage
        </HashLink>
      </button>
    </div>
  </div>
);
