import React from "react";

export default function Loader() {
  return (
    <div className="loader-wrapper">
      <div className="loader-wrapper__loader"></div>
      <div className="loader-wrapper__loaderText">Loading</div>
      <div className="loader-wrapper__section section--left"></div>
      <div className="loader-wrapper__section section--right"></div>
    </div>
  );
}
