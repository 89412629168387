import React, { useContext } from "react";
import { HashLink } from "react-router-hash-link";

import BackEndIcon from "./Icons/BackEndIcon";
import FrontEndIcon from "./Icons/FrontEndIcon";
import DesignIcon from "./Icons/DesignIcon";
import DownArrow from "./Icons/DownArrow";
import Circles from "./Icons/Circles";

// import Backdrop from "./Backdrop";
// <div className="topBG"></div>

import { RoleContext } from "../context/roleContext";

const Intro = props => {
  const { developer, handleSwitch } = useContext(RoleContext);
  return (
    <section className="introSection">
      <div
        className={`introPage ${
          !developer ? "desIntro devOutro" : "devIntro desOutro"
        }`}
      >
        <div className="desDev">
          <button
            disabled={!developer}
            onClick={handleSwitch}
            className={`role developer ${developer ? "" : "active"}`}
          >
            Developer
          </button>
          <div className="desDev__divider">|</div>
          <button
            disabled={developer}
            onClick={handleSwitch}
            className={`role designer ${!developer ? "" : "active"}`}
          >
            Designer
          </button>
        </div>
        <div className="textContainer">
          <h3
            className={`textContainer__Header ${
              !developer ? "Header--design" : "Header--dev"
            }`}
          >
            {!developer
              ? `Hello, I'm David, a front-end web-developer
             `
              : `I'm also a creative developer with a background in design`}
          </h3>

          {!developer ? (
            <p className="textContainer__BodyText Body--Design">
              Originally from the UK, now Berlin based, I am a web developer
              with graphic design experience. Recently, I moved into the world
              of coding, through a full-stack web-dev course and continuous
              self-initiated study. Constantly learning and always keen to
              improve, I have a solid basis for developing apps and websites
              from front to back-end, using current technologies, built on a
              responsive UI.
            </p>
          ) : (
            <p className="textContainer__BodyText Body--Dev">
              Working on branding harmonisation, and producing work in a
              multitude of design formats, it's often the minor details that
              make a project stand out. I strive to carry this ethos through to
              web development, by creating, through a simplified content
              structure, innovative websites or applications that are
              concept-driven, creative and unique, with innovative interactions.
            </p>
          )}
        </div>
        <div className="greyBlock"></div>
        {/* DESIGN BLOCK*/}
        <div className="projectBlock blockDes">
          <div className="frontEndSection">
            <DesignIcon />
            <Circles />

            <h3 className="headerIntro">Design</h3>

            <h4 className="subHeaderIntro">Things I like to design:</h4>
            <p className="bodyTextIntro">
              UI interfaces, identity design & brand harmonisation
            </p>
            <br />
            <h4 className="subHeaderIntro">Design tools:</h4>
            <p className="bodyTextIntro">
              Photoshop, Illustrator, InDesign, Figma, Sketch
            </p>
          </div>
        </div>
        {/*DEV BLOCK*/}
        <div className="projectBlock blockDev">
          <div className="frontEndSection">
            <FrontEndIcon />
            <Circles />

            <h3 className="headerIntro">Development</h3>
            <h4 className="subHeaderIntro">Languages I use:</h4>
            <p className="bodyTextIntro">
              HTML5, CSS3/SASS, Javascript, jQuery
            </p>
            <br />
            <h4 className="subHeaderIntro">Development tools:</h4>
            <p className="bodyTextIntro">
              Github, Postman, DBeaver, Mongoose, Atom, Wordpress, Woocommerce
            </p>
          </div>
        </div>

        {/* VIEW PROJECTS  - DEV PAGE */}
        <HashLink className="projectBlock viewProjects" smooth to="/#folio">
          <DownArrow />
        </HashLink>
        {/* FRONT END */}
        <div className="projectBlock frontEndStack">
          <div className="frontEndSection">
            <FrontEndIcon />
            <Circles />
            <h3 className="headerIntro">Front-end</h3>
            <p className="bodyTextIntro">
              HTML5, CSS3/SASS, JS/JQuery, Vue, React, Next, EJS
            </p>
          </div>
        </div>
        {/* BACK END */}
        <div className="projectBlock backEndStack">
          <div className="frontEndSection">
            <BackEndIcon />
            <Circles />

            <h3 className="headerIntro">Back-end</h3>
            <p className="bodyTextIntro">
              Node.js, Socket.IO, PostgreSQL, MongoDB
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Intro;

// {/* PROFILE IMAGE */}
// <div className="projectBlock profileImage">
//   <div className="glitch">
// 	<div className="glitchit" alt="profile_image"></div>
// 	<div className="glitchit" alt="profile_image"></div>
// 	<div className="glitchit" alt="profile_image"></div>
// 	<div className="glitchit" alt="profile_image"></div>
// 	<div className="glitchit" alt="profile_image"></div>
//   </div>
// </div>

// circle top right
// <div className="projectBlock profileCircle"></div>
