import React from "react";

import Intro from "./Intro";
import Folio from "./Folio";
import Connect from "./Connect";

const Main = props => {
  const { projectInfo } = props;
  return (
    <>
      <Intro />
      <Folio {...props} projectInfo={projectInfo} />
      <Connect />
    </>
  );
};
export default Main;
