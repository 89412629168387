import React from "react";

const GitHub = () => (
  <svg
    version="1.1"
    id="gitHub"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20px"
    // height="auto"
    viewBox="0 0 18.653 18.653"
    enableBackground="new 0 0 18.653 18.653"
  >
    <path
      className="iconFill"
      d="M6.708,18.653c-0.078,0-0.163-0.008-0.254-0.024C2.562,17.307,0,13.666,0,9.549C0,4.284,4.184,0,9.326,0
  	s9.327,4.284,9.327,9.549c0,4.114-2.56,7.754-6.368,9.057c-0.173,0.039-0.263,0.048-0.345,0.048c-0.59,0-1.019-0.431-1.019-1.023
  	l0.003-0.5c0.003-0.462,0.007-1.16,0.007-1.961c0-0.815-0.306-1.148-0.398-1.23l-0.971-0.856l1.286-0.146
  	c1.812-0.206,3.486-0.815,3.486-3.856c0-0.783-0.254-1.464-0.754-2.025l-0.236-0.265l0.131-0.33
  	c0.049-0.125,0.257-0.729,0.031-1.585c-0.301,0.062-0.843,0.248-1.659,0.814l-0.224,0.155l-0.262-0.074
  	c-0.632-0.18-1.336-0.277-2.037-0.281c-0.696,0.003-1.4,0.101-2.031,0.28L7.032,5.843L6.808,5.688
  	C5.985,5.117,5.439,4.933,5.146,4.875C4.921,5.731,5.128,6.334,5.178,6.459l0.13,0.33L5.073,7.053
  	c-0.5,0.562-0.754,1.244-0.754,2.026c0,3.041,1.671,3.653,3.478,3.863l1.266,0.147l-0.948,0.852
  	c-0.087,0.078-0.298,0.318-0.371,0.852l-0.043,0.312l-0.286,0.132c-0.454,0.208-1.292,0.547-2.264,0.047
  	c0.333,0.272,0.984,0.46,1.871,0.26l0.696-0.154l0.016,2.241C7.732,18.213,7.292,18.653,6.708,18.653z M6.708,17.507v0.571
  	l0.004-0.57c0.011,0.004,0.022,0.008,0.033,0.012l-0.033-0.017c-0.001,0-0.002,0-0.003,0.001l0-0.001v0.001
  	c-0.002,0-0.004,0-0.005,0.001C6.704,17.505,6.706,17.506,6.708,17.507z M9.326,1.15c-4.508,0-8.176,3.768-8.176,8.398
  	c0,3.565,2.175,6.723,5.431,7.912l-0.005-0.689c-1.852,0.137-2.744-1.077-2.929-1.708c-0.283-0.732-0.668-0.955-0.684-0.964
  	c-0.245-0.165-0.79-0.547-0.618-1.092c0.108-0.343,0.45-0.54,0.938-0.54c1.226,0.087,1.825,1.182,1.85,1.229
  	c0.261,0.458,0.594,0.678,1.039,0.678c0.181,0,0.354-0.037,0.497-0.081c0.03-0.12,0.065-0.236,0.108-0.346
  	c-1.436-0.309-3.609-1.274-3.609-4.868c0-0.958,0.28-1.808,0.834-2.534C3.878,6.054,3.777,5.168,4.193,4.089l0.099-0.254L4.551,3.75
  	C4.736,3.688,5.634,3.541,7.24,4.593c0.664-0.165,1.363-0.251,2.084-0.255c0.725,0.004,1.426,0.089,2.09,0.255
  	c1.604-1.052,2.502-0.904,2.687-0.843l0.26,0.085l0.098,0.254c0.417,1.079,0.316,1.965,0.192,2.457
  	c0.554,0.725,0.834,1.576,0.834,2.534c0,3.593-2.175,4.556-3.614,4.862c0.138,0.36,0.212,0.777,0.212,1.228
  	c0,0.804-0.005,1.505-0.008,1.968l-0.001,0.318c3.244-1.169,5.43-4.333,5.43-7.906C17.503,4.918,13.835,1.15,9.326,1.15z"
    />
  </svg>
);
export default GitHub;
