import React from "react";

import Mailto from "react-protected-mailto";

const Mail = () => (
  <Mailto
    email="info@dpdesigns.eu"
    headers={
      ({ Subject: "Work query from the website" },
      { cc: "davep_graafinen@yahoo.co.uk" })
    }
  />
);

export default Mail;
