import React from "react";

//components
import Logo from "./Icons/Logo";
import MainNav from "./MainNav";
import ThemeSwitch from "./helpers/ThemeSwitch";

const Header = props => {
  return (
    <div className="headerContainer">
      <div className="headerWrapper">
        <Logo />
        <ThemeSwitch />
        <MainNav />
      </div>
    </div>
  );
};
export default Header;
