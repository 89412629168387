import React from "react";
import Mail from "./helpers/Mailto";

const Footer = () => {
  return (
    <footer className="folioFooter">
      <div className="container" id="contact">
        <div className="details">
          <h5>
            mobile: <span>+49 157 56428602</span>
          </h5>
          <h5>
            email:
            <span>
              <Mail />
            </span>
          </h5>
        </div>
        <div className="message">
          <h4>Say hello...</h4>
          <h5>
            This is the personal portfolio of developer/designer, David Parsons.
            Selectively available, please feel free to drop me a work query or
            general enquiry here - &nbsp;
            <span>
              <Mail email="here">here</Mail>
            </span>
          </h5>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
