import React, { useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// data
import folioData from "./folioData.json";

// contexts
import { ThemeContext } from "./context/themeContext";
import { LoadedContext } from "./context/loadedContext";
import { RoleContext } from "./context/roleContext";

//components
import Topbutton from "./components/helpers/Topbutton";
import Loader from "./components/helpers/Loader";
import Page from "./components/helpers/Page";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import Header from "./components/Header";
import { NoMatch } from "./components/helpers/NoMatch";
import ScrollToTop from "./components/helpers/ScrollToTop";

const App = props => {
  const { developer } = useContext(RoleContext);
  const { getTheme, setTheme } = useContext(ThemeContext);
  const { isLoaded, showLoader } = useContext(LoadedContext);
  useEffect(() => {
    showLoader();
    setTheme();
    return () => {
      getTheme();
    };
  }, [getTheme, setTheme, showLoader]);

  const { folioDetails, developerDetails } = folioData;
  const projectInfo = developer ? [...folioDetails] : [...developerDetails];
  const ref = React.createRef();
  return (
    <>
      {isLoaded ? (
        <ScrollToTop>
          <Topbutton />
          <Header />
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  in={true}
                  key={location.key}
                  classNames="fade"
                  timeout={500}
                >
                  <Switch location={location}>
                    <Route
                      id="intro"
                      exact
                      path="/"
                      render={props => (
                        <Main {...props} projectInfo={projectInfo} />
                      )}
                    />
                    <Route
                      exact
                      path="/projects/:projectid"
                      render={props => (
                        <Page ref={ref}>
                          <Projects {...props} projectInfo={projectInfo} />
                        </Page>
                      )}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
          <Footer />
        </ScrollToTop>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default App;
