import React from "react";

const Connect = () => {
  return (
    <svg
      version="1.0"
      id="ConnectIcon_1_"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="19.899px"
      height="17.202px"
      viewBox="0 0 19.899 17.202"
      enableBackground="new 0 0 19.899 17.202"
    >
      <path
        className="iconFill"
        d="M13.624,17.202l-5.271-4.556H6.333c-1.851,0-3.518-0.69-4.694-1.944C0.502,9.492-0.09,7.881,0.011,6.281
  C0.01,2.836,2.846,0,6.333,0h7.244c4.151,0,6.323,3.181,6.323,6.323c0,2.533-1.672,6.297-6.275,6.323V17.202z M6.333,1.318
  c-2.759,0-5.004,2.245-5.004,5.004C1.249,7.584,1.725,8.869,2.599,9.8c0.654,0.697,1.833,1.527,3.733,1.527h2.511l3.462,2.993
  v-2.993h1.271c3.958,0,5.004-3.274,5.004-5.005c0-2.487-1.719-5.004-5.004-5.004H6.333z"
      />
      <path
        opacity="0"
        className="iconCenterFill"
        d="M6.333,1.318c-2.759,0-5.004,2.245-5.004,5.004C1.249,7.584,1.725,8.869,2.599,9.8
  c0.654,0.697,1.833,1.527,3.733,1.527h2.511l3.462,2.993v-2.993h1.271c3.958,0,5.004-3.274,5.004-5.005
  c0-2.487-1.719-5.004-5.004-5.004H6.333z"
      />
    </svg>
  );
};

export default Connect;
