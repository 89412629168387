import React from "react";

const Connect = () => (
  <div className="connectSection" id="connect">
    <div className="connectWrapper">
      <div className="contactsInfo">
        <h3 className="sectionSubHeader">
          Interesting project? <br /> Got a work query?
        </h3>
        <p className="sectionBody">
          If you would like my involvement in your project, I'm keen to hear all
          about it, please send me a message, hopefully we can work together.
        </p>
      </div>
    </div>
  </div>
);

export default Connect;
