import React from "react";

const TriangleArrowBtn = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22px"
      height="22px"
      className="iconMain"
      viewBox="0 0 30 30"
      enableBackground="new 0 0 30 30"
    >
      <path
        className="iconFill"
        d="M15,0C6.729,0,0,6.729,0,14.999C0,23.271,6.729,30,15,30c8.271,0,15-6.729,15-15.001C30,6.729,23.271,0,15,0
  		z M15,28.672c-7.539,0-13.673-6.134-13.673-13.673S7.461,1.327,15,1.327c7.538,0,13.672,6.134,13.672,13.672
  		C28.672,22.538,22.538,28.672,15,28.672z M15,22.332l7.249-12.558h-14.5L15,22.332z M15,19.477l-4.751-8.228h9.501L15,19.477z"
      />
    </svg>
  );
};

export default TriangleArrowBtn;
