import React from "react";

const FrontEndIcon = () => {
  return (
    <figure className="iconBorder">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className="iconMain"
        width="15.21px"
        height="15.21px"
        viewBox="0 0 15.21 15.21"
        enableBackground="new 0 0 15.21 15.21"
      >
        <path
          className="iconFill"
          d="M11.536,15.209l-2.858-2.857l-2.396,2.396L0,0l14.749,6.282l-2.396,2.396l2.857,2.858L11.536,15.209z
M8.677,10.489l2.858,2.857l1.811-1.811l-2.857-2.858l1.948-1.948L2.044,2.027l4.685,10.409L8.677,10.489z"
        />
        <path
          opacity="0"
          className="iconCenterFill"
          d="M8.677,10.489l2.858,2.857l1.811-1.811l-2.857-2.858l1.948-1.948L2.044,2.027l4.685,10.409
L8.677,10.489z"
        />
      </svg>
    </figure>
  );
};

export default FrontEndIcon;
