import React, { useEffect, useContext } from "react";
import { HashLink } from "react-router-hash-link";

// contexts
import { DeviceContext } from "../context/deviceContext";

// icons
import Connect from "./Icons/Connect";
import About from "./Icons/About";
import Folio from "./Icons/Folio";

const MainNav = () => {
  const { isDesktop, updateDesktop } = useContext(DeviceContext);

  const setHeight = isDesktop ? "" : "25%";
  useEffect(() => {
    window.addEventListener("resize", updateDesktop);
    return () => {
      window.removeEventListener("resize", updateDesktop);
    };
  }, [updateDesktop]);

  const showMobileNav = () => {
    document.getElementById("mobileMenu").classList.toggle("active");
  };
  return (
    <nav className={isDesktop ? "navTop" : "mobileNav"}>
      <ul id={isDesktop ? "" : "mobileMenu"}>
        <li>
          <HashLink
            className="navLink about"
            smooth
            to="/#intro"
            onClick={!isDesktop ? showMobileNav : null}
            aria-label="About"
          >
            <About setHeight={setHeight} />
          </HashLink>
        </li>
        <li>
          <HashLink
            className="navLink portfolioIcon"
            smooth
            to="/#folio"
            onClick={!isDesktop ? showMobileNav : null}
            aria-label="Portfolio"
          >
            <Folio setHeight={setHeight} />
          </HashLink>
        </li>
        <li>
          <HashLink
            className="navLink contact"
            smooth
            to="/#connect"
            onClick={!isDesktop ? showMobileNav : null}
            aria-label="Connect"
          >
            <Connect setHeight={setHeight} />
          </HashLink>
        </li>
      </ul>
      <div className={isDesktop ? null : "mobile-btn"} onClick={showMobileNav}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};
export default MainNav;
