import React, { Fragment, useContext, useEffect } from "react";
import { Route, useParams } from "react-router-dom";

//components
import ProjectList from "./ProjectList";

//contexts
import { DeviceContext } from "../context/deviceContext";
import { RoleContext } from "../context/roleContext";

import Projects from "./Projects";

//Icons
const Folio = (props) => {
  const { projectid } = useParams();
  const { isDesktop, updateDesktop } = useContext(DeviceContext);
  const { developer } = useContext(RoleContext);
  useEffect(() => {
    window.addEventListener("resize", updateDesktop);
    return () => {
      window.removeEventListener("resize", updateDesktop);
    };
  }, [updateDesktop]);

  const { url } = props.match;
  const { projectInfo } = props;

  return (
    <Fragment>
      <div id="folio" className="portfoliotext">
        <div className="wrapper">
          <h3 className="sectionSubHeader">
            {!developer ? "Developer Works" : "Design Works"}
          </h3>
          <p className="sectionBody">
            {!developer
              ? `A selection of apps I have built, mostly React based projects with Node.js used on the backend and MongoDB or Postgres for database purposes when required. Mostly I use SCSS for styling and try to address UI concerns with some simple but effective and responsive designs`
              : `A selection of past design work, mostly print but including some web and e-commerce site designs`}
          </p>
          <h4 className="mobileMessage">
            &#123; Click for more info - best viewed on desktop &#125;
          </h4>
        </div>
      </div>
      <div className="portfolioGrid">
        <ProjectList
          isDesktop={isDesktop}
          projectInfo={projectInfo}
          developer={developer}
        />
      </div>
      <Route
        exact
        path={`${url}/projects/:projectid`}
        render={(props) => {
          projectInfo.find(({ id }) => id === projectid);
          return <Projects {...props} projectInfo={projectInfo} />;
        }}
      />
    </Fragment>
  );
};
export default Folio;
