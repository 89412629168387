import React, { useState, useEffect, useContext } from "react";

//routing
import { useParams } from "react-router-dom";
import { DeviceContext } from "../context/deviceContext";

import * as _ from "underscore";

const SubHeader = props => {
  const [smallerSub, setSmallerSub] = useState(false);
  const { isDesktop, updateDesktop } = useContext(DeviceContext);
  const { projectid } = useParams();
  console.log("props", props);
  const { match, projectInfo } = props;
  const { projectName, id } = projectInfo[match.params.projectid];
  console.log("props", props);

  const shrinkSubHeader = () => {
    var subdistanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkAt = 35;
    subdistanceY > shrinkAt ? setSmallerSub(true) : setSmallerSub(false);
  };

  const handleInputThrottled = _.debounce(shrinkSubHeader, 100);
  useEffect(() => {
    window.addEventListener("scroll", handleInputThrottled, false);
    window.addEventListener("resize", updateDesktop);
    return () => {
      window.removeEventListener("scroll", handleInputThrottled, false);
      window.removeEventListener("resize", updateDesktop);
    };
  }, [updateDesktop, handleInputThrottled]);

  let projectListLength = projectInfo.length - 1;

  const handleClickPrevious = () => {
    let previousparams = Number(projectid) - 1;
    previousparams < 0
      ? props.history.push(`/projects/${projectListLength}`)
      : props.history.push(`/projects/${previousparams}`);
  };
  //
  const handleClickNext = () => {
    let nextparams = Number(projectid) + 1;
    nextparams > projectListLength
      ? props.history.push(`/projects/0`)
      : props.history.push(`/projects/${nextparams}`);
  };

  // const imgList = projectDetails.map(({ image }, index) => (
  //   <li key={index}>
  //     <HashLink className="projectImages" smooth to={`${id}#image${index}`}>
  //       Description
  //     </HashLink>
  //     <Route
  //       path={`#image${index}`}
  //       render={props => <Projects {...props} folioInfo={folioInfo} />}
  //     />
  //   </li>
  // ));
  return (
    <header className={`subheader ${smallerSub ? "smaller" : ""}`}>
      <div className="wrapper">
        <div className="subHeaderContainer">
          <div id="clients" className="clients">
            <h3 className="projNum">{id}</h3>
            <h5>
              <span>Project: </span> {projectName}
            </h5>
          </div>
          <div className="nextLast">
            <button className="last" onClick={handleClickPrevious}>
              {isDesktop ? "previous" : "<"}
            </button>
            <button className="next" onClick={handleClickNext}>
              {isDesktop ? "next" : ">"}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SubHeader;
